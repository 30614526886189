/* eslint-disable no-constant-condition */
/**
 * External Dependencies
 */
import 'jquery';
// import dialogPolyfill from 'dialog-polyfill';
// import "jquery-accordion";
// import "slick-carousel";

// eslint-disable-next-line no-unused-vars
// import { Fancybox, Carousel } from "@fancyapps/ui";
import { Fancybox } from "@fancyapps/ui";
import { Autoplay } from '@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js';
// Carousel.Plugins.Autoplay = Autoplay;

window.Fancybox = Fancybox;
// window.Carousel = Carousel;

if(true && Fancybox) {
  Fancybox.bind('[data-fancybox]', {
    // hideScrollbar: false,
    draggable: false,
    contentClick: false,
    wheel: "pan",
    autoFocus: false,
    touch: false,
  });
}

// Flexpoint hero slideshow
if(false && document.getElementById('hero-carousel')) {
  // eslint-disable-next-line no-unused-vars
  const heroCarousel = new Carousel(document.getElementById('hero-carousel'), {
    slidesPerPage: 1,
    Autoplay: {
      autoStart: true,
      pauseOnHover: false,
      // showProgress: false,
      timeout     : 6000,
      // timeout     : 200,
      // transition: "crossfade",
      // transition: "fadeSlow",
    },
    draggable: false,
    Navigation: false,
    Dots      : false,
  }, { Autoplay });
  window.heroCarousel = heroCarousel;
  // heroCarousel.plugins.Autoplay.start()

}

if(false) { // testimonials
  const prevNavSVG = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor" stroke="currentColor" stroke-width="2"/><path d="M13.5 8L9.5 12L13.5 16" stroke="#94A5A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
  const nextNavSVG = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor" stroke="currentColor" stroke-width="2"/><path d="M10.5 8L14.5 12L10.5 16" stroke="#94A5A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

  // eslint-disable-next-line no-unused-vars
  const testimonialsCarousel = new Carousel(document.querySelector("#testimonials-carousel"), {
    slidesPerPage: 1,
    Autoplay     : {
    },
    Navigation: {
      prevTpl: prevNavSVG,
      nextTpl: nextNavSVG,
    },
    Dots: false,
  });
  testimonialsCarousel.plugins.Autoplay.start()
}

if(false) { // accordions
  $("[data-accordion]").accordion();
}

if(true) { // sermo share
  const share_url = document.location.href;
  const title = document.title;
  const description = "description";
  const username = "username";
  const subject = "subject";
  const message = "message";

  let sermoShare = {};

  sermoShare.queryString = function(e) {
    var t, n;
    return (function() {
      var r = [];
      for (t in e)
        (n = e[t]), null != n && r.push(t + "=" + encodeURIComponent(n));
      return r;
    })().join("&");
  };

  sermoShare.getWindowSize = function() {
    var e, t, n, r;
    return (
      (e = document.body),
      (t = document.documentElement),
      (n = window.innerHeight),
      (r = window.innerWidth),
      {
        height: n || t.clientHeight || e.clientHeight,
        width: r || t.clientWidth || e.clientWidth
      }
    );
  };

  // Will be true if mobile or tablet, false otherwise
  sermoShare.mobile = (function() {
    let check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  })();

  sermoShare.networks = {
    email:
      "mailto:?to=&" +
      sermoShare.queryString({
        subject: subject || "I'd like to share a link with you",
        body: message || "" + share_url
      }),
    facebook:
      "https://www.facebook.com/sharer.php?" +
      sermoShare.queryString({
        t: title,
        u: share_url
      }),
    linkedin:
      "https://www.linkedin.com/shareArticle?" +
      sermoShare.queryString({
        title: title,
        url: share_url
      }),
    pinterest:
      "https://pinterest.com/pin/create/button/?" +
      sermoShare.queryString({
        description: title,
        media: "image",
        url: share_url
      }),
    reddit:
      "https://reddit.com/submit?" +
      sermoShare.queryString({
        title: title,
        url: share_url
      }),
    tumblr:
      "https://www.tumblr.com/share?" +
      sermoShare.queryString({
        t: title,
        u: share_url,
        v: 3
      }),
    twitter:
      "https://twitter.com/intent/tweet?" +
      sermoShare.queryString({
        text: title || description,
        url: share_url,
        via: username
      })
  };

  sermoShare.open = function(t) {
    var n, r, o, i;
    return sermoShare.mobile
      ? window.open(t, "_blank")
      : t.indexOf("mailto:") > -1
      ? (document.location = t)
      : ((o = sermoShare.getWindowSize().height),
        (i = sermoShare.getWindowSize().width),
        (n = Math.min(600, 0.6 * o)),
        (r = Math.min(800, 0.8 * i)),
        window.open(
          t,
          "",
          [
            "height=" + n,
            "left=" + (i - r) / 2,
            "top=" + (o - n) / 2,
            "width=" + r,
            "status=1",
            "toolbar=0"
          ].join(",")
        ));
  };

  window.sermoShare = sermoShare;

  jQuery(($) => {
    $("a.sermo-share").click(function(e) {
      e.stopPropagation();
      e.preventDefault();

      let network = $(this).data("network");

      if (network === "print") {
        window.print();
      } else {
        sermoShare.open(sermoShare.networks[network]);
      }
    });
  });
}

if(true) { // donate form other input
  jQuery(($) => {
    const $donateForm = $("form.donate-form");

    if(($donateForm.length < 1) || (typeof gform == 'undefined')) {
      return;
    }

    // eslint-disable-next-line
    gform.addAction(
      "gform_input_change",
      function(elem, formId, fieldId) {
        if (elem.type === "radio" && elem.value.indexOf("other") > -1) {
          $donateForm
            .find(".donate-other-field input")
            .focus()
            .select();
        }
      },
      100
    );
  });
}

if(false) { // side menu toggles
  const sermenu = {
    $body: $('body'),
    $allWrapper: $('.app-wrapper'), // menu #st-container
    $pusher: $('.menu-pusher'),
    // $button: $('#menu-toggle-open'),
    $button: $('[id|="menu-toggle-open"]'),
    $closeButton: $('#menu-toggle-close'),
    init: function() {
      // console.log("SERMENU", "init()");
      // console.log("SERMENU", this.$allWrapper);
      // console.log("SERMENU", this.$pusher);
      // console.log("SERMENU", this.$button);
      // set event listeners
      this.$button.on('click', function() { sermenu.open(); })
      this.$pusher.on('click', function(el) { sermenu.closeMenu(el); })
      this.$closeButton.on('click', function() { sermenu.closeMenuForSure(); })
      // this.$button.on('click', '.app-wrapper', function() { this.open(); })
      // this.$pusher.on('click', '.app-wrapper', function(el) { this.closeMenu(el); })
    },
    open: function() {
      console.log('SERMENU', 'open()');
      this.$allWrapper.addClass('sermenu-open');
      this.$body.css('position', 'fixed').css('width', '100%');
    },
    closeMenu: function(el) {
      if (el.target.classList.contains('menu-pusher')) {
        this.closeMenuForSure();
      }
    },
    closeMenuForSure: function() {
      // console.log('SERMENU', 'closeMenu()');
      this.$allWrapper.removeClass('sermenu-open');
      this.$body.css('position', 'relative').css('width', '');
    },
  };

  sermenu.init();
}

// hiding sticky nav on home page
if(false) {
  const sershownav = {
    init: function() {
      if(!$('body').hasClass('home')) {
        return false;
      }

      window.onscroll = function () {
        sershownav.checkScroll();
      };
    },
    checkScroll: function() {
      if($('#ismob').is(':visible')) {
        return false;
      }
      const topNavHeight = 2;
      if (document.body.scrollTop > topNavHeight || document.documentElement.scrollTop > topNavHeight) {
        //
        // $('#main-header-home').attr('id', 'main-header');
        $('#main-header').fadeIn();
        $('#main-header-home').fadeOut();
      } else {
        // $('#main-header').attr('id', 'main-header-home');
        $('#main-header').fadeOut();
        $('#main-header-home').fadeIn();
      }
    },
  }

  sershownav.init();
}

// Job filter BS
if(false) {
  jQuery(($) => {
    const serjobfilter = {
      init: function() {
        //
        // console.log('FILTER INIT');
        // console.log($('.job-location-filters a'));
        const $filterButtons = $('.job-location-filters a');
        $filterButtons.on('click', function(e) {
          // console.log('=============FILTER CLICK====================');
          e.preventDefault();
          e.stopPropagation();

          // Set the clicked filter to current
          $filterButtons.removeClass('current');
          $(this).addClass('current');

          const location = $(this).data('location');
          const $positionCards = $('.position-card');

          // console.log('Location: ', "XX" + location + "XX");
          // class of position card: .position-card
          if(location == 'all') {
            $positionCards.show();
          } else {
            // $positionCards.find('[data-location~=" ' + location + ' "').css('scale', '3');
            // $positionCards.filter('[data-location~=" ' + location + ' "]').css('scale', '3');
            // const $ourCards = $positionCards.filter('[data-location~="' + location + '"]');
            // const $outCards
            // console.log($ourCards);
            // $ourCards.fadeIn();
            // cards to show
            $positionCards.filter('[data-location~="' + location + '"]').show();
            // cards to hide
            $positionCards.filter(':not([data-location~="' + location + '"])').hide();
            // console.log($positionCards.filter('[data-location~="' + location + '"]'));
            // console.log($positionCards.filter(':not([data-location~="' + location + '"])'));
            // $positionCards.filter('[data-location~="' + location + '"]').fadeIn().addBack(':not([data-location~="' + location + '"])');
          }
        });
      },
    }

    serjobfilter.init();
  });
}